<template>
  <applicants :applicantList="applicantList" @deleteApplicant="onDeleteApplicant"/>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex'
import { getResponses, deleteResponse } from '../helpers/firebase-functions'
import Applicants from '@/components/applicants'
const { mapActions: mapActionsCalendar } = createNamespacedHelpers('calendar')

export default ({
  components: { Applicants },
  data: function () {
    return {
      applicantList: []
    }
  },
  computed: {
    ...mapState('auth', ['uid'])
  },
  methods: {
    ...mapActionsCalendar(['authenticateSchedulerlyClient']),
    async onDeleteApplicant (response) {
      const ownerId = this.uid
      const scenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
      const { responseId } = response
      await deleteResponse({ ownerId, scenarioId, responseId })
      this.applicantList = await getResponses({ ownerId, scenarioId })
    }
  },
  async mounted () {
    this.authenticateSchedulerlyClient()
    const ownerId = this.uid
    const scenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
    this.applicantList = await getResponses({ ownerId, scenarioId })
  }
})
</script>
