<template>
  <v-card>
    <v-card-title>
      {{$t('m.applicants.card.title')}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('m.applicants.searchField.label')"
        single-line
        hide-details
      />
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-data-table :headers="headers" :items="_applicants" :search="search" @click:row="gotoApplicantDetails">
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon @click.stop="deleteApplicant(item)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer/>
            <v-col><v-btn @click="exportClicked">{{$t('m.applicants.export.buttonText')}}</v-btn></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="deleteDialog" width="330">
      <confirm
        :text="deleteDialogText"
        @cancelClicked="onDeleteCancel"
        @okClicked="onDeleteOK"
      />
    </v-dialog>
    <v-dialog v-model="exportDialog" width="400">
      <export @complete="exportDialog = false" />
    </v-dialog>
  </v-card>
</template>

<script>
import format from 'date-fns/format'
import { mapState } from 'vuex'
import Confirm from '@/components/alert/confirm'
import Export from './export'

export default ({
  name: 'Applicants',
  components: {
    Confirm,
    Export
  },
  props: {
    applicantList: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      search: '',
      headers: [
        { text: this.$t('m.applicants.table.created'), sortable: true, align: 'start', value: '_createdAt' },
        { text: this.$t('m.applicants.table.name'), sortable: true, align: 'start', value: '_applicantName' },
        { text: this.$t('m.applicants.table.email'), sortable: true, align: 'start', value: '_applicantEmail' },
        { text: this.$t('m.applicants.table.telNo'), sortable: true, align: 'start', value: '_applicantPhone' },
        { text: this.$t('m.applicants.table.application'), sortable: true, align: 'center', value: '_formCompleted' },
        { text: this.$t('m.applicants.table.action'), sortable: false, align: 'center', value: 'action' }
      ],
      deleteDialog: false,
      deleteItem: null,
      exportDialog: false
    }
  },
  computed: {
    ...mapState('auth', ['uid']),
    _applicants () {
      const applicants = this.applicantList.filter(applicant => applicant.archived ? !applicant.archived : true)
      return applicants.map(applicant => {
        const { createdAt, applicantName, applicantEmail, applicantPhone, formCompleted } = applicant
        return {
          ...applicant,
          _createdAt: format(new Date(createdAt), 'yyyy/MM/dd HH:mm'),
          _applicantName: applicantName || '-',
          _applicantEmail: applicantEmail || '-',
          _applicantPhone: applicantPhone || '-',
          _formCompleted: formCompleted ? this.$t('m.applicants.table.completed') : this.$t('m.applicants.table.notCompleted')
        }
      })
    },
    deleteDialogText () {
      return this.deleteItem && this.deleteItem.applicantName
        ? this.$t('m.applicants.deleteDialog.text', { applicantName: this.deleteItem.applicantName })
        : this.$t('m.applicants.deleteDialog.noApplicantNameText')
    }
  },
  methods: {
    async gotoApplicantDetails (item) {
      this.$router.push(`/applicant-details/${item.responseId}`)
    },
    deleteApplicant (item) {
      this.deleteDialog = true
      this.deleteItem = item
    },
    onDeleteCancel () {
      this.deleteDialog = false
    },
    onDeleteOK () {
      this.deleteDialog = false
      this.$emit('deleteApplicant', this.deleteItem)
    },
    exportClicked () {
      this.exportDialog = true
    }
  }
})
</script>
