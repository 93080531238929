<template>
  <v-card>
    <v-card-title>{{$t("m.confirm")}}</v-card-title>
    <v-card-text>{{text}}</v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn @click="onCancelClicked">{{$t("m.cancel")}}</v-btn>
      <v-btn @click="onOKClicked">{{$t("m.ok")}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'confirm',
  props: {
    text: {
      type: String,
      required: true
    }
  },
  methods: {
    onCancelClicked () { this.$emit('cancelClicked') },
    onOKClicked () { this.$emit('okClicked') }
  }
}
</script>
