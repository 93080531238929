<template>
  <div>
    <DateFilter v-bind="filterProp" @download="downloadCSV" />
    <a v-show="false" ref="doDownload" :href="csvUrl" download="applicant.csv">click</a>
  </div>
</template>
<script>
import DateFilter from '@/components/shared/startEndDateFilter'
import { exportApplicantsToCSV } from '@/helpers/firebase-functions'

export default {
  props: {
    color: { default: () => { return 'amber lighten-2' } }
  },
  data: () => ({
    csvUrl: ''
  }),
  components: { DateFilter },
  methods: {
    async downloadCSV ({ start, end }) {
      const { data } = await exportApplicantsToCSV({ start: start.toISOString(), end: end.toISOString() })
      const blob = new Blob([data])
      this.csvUrl = URL.createObjectURL(blob)

      this.$nextTick(() => {
        this.$refs.doDownload.click()
        this.$emit('complete')
      })
    }
  },
  computed: {
    filterProp () {
      return {
        color: this.color,
        title: this.$i18n.t('m.applicant.download')
      }
    }
  }
}
</script>
